export const platfroms = ['', 'BWG', 'Physical Gold', 'Tether Gold', 'Paper Gold']
export const representative = [
  'Representative',
  <span>
    Bretton Woods <i>digital</i>
  </span>,
  'Degussa, Hereaus etc.',
  'Tether',
  'Xetra-Gold',
]

export const tradability = ['Tradability', 'Yes', 'No', 'Yes', 'Yes']
export const goldBacked = ['Gold-Backed', 'Yes', 'Yes', 'Unknown', 'Unknown']
export const storageFees = ['Storage Fee', 'Yes', 'Yes', 'No', 'No']
export const ownership = ['Ownership of Gold', 'Yes', 'Yes', 'Unknown', 'No']
