import {useState} from 'react'
import {alpha} from '@mui/material/styles'
import {MenuItem, Stack, Popover, Button} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'

import {navLinks} from '../Navbar/Navbar.config'

const HamburgerMenu = () => {
  const [open, setOpen] = useState(null)

  const handleOpen = event => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }

  return (
    <>
      <Button
        onClick={handleOpen}
        color='inherit'
        sx={{
          textTransform: 'uppercase',
          bgcolor: ({palette: {primary, action}}) =>
            open ? alpha(primary.main, action.focusOpacity) : 'unset',
        }}
      >
        <MenuIcon />
      </Button>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
        PaperProps={{
          sx: {
            p: 1,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack spacing={0.75}>
          {navLinks.map(option => (
            <MenuItem
              component='a'
              key={option.id}
              href={option.link}
              onClick={() => handleClose()}
              target={option.target}
            >
              {option.text}
            </MenuItem>
          ))}
        </Stack>
      </Popover>
    </>
  )
}

export default HamburgerMenu
