import {Route, Routes, Navigate} from 'react-router-dom'

import LayoutMain from 'layout'
import HomeView from 'views/Home'
import LegalNotice from 'views/LegalNotice'
import DocumentView from './views/DocumentView/DocumentView'
import Affiliate from 'views/Affiliate'

const App = () => (
  <LayoutMain>
    <Routes>
      <Route path='/' element={<HomeView />} />
      <Route path='/legal-notice' element={<LegalNotice />} />
      <Route path='/affiliate' element={<Affiliate />} />
      <Route path='/affiliate/legal-notice' element={<LegalNotice />} />
      <Route
        path='/privacy-statement'
        element={
          <DocumentView
            title='Privacy Statement'
            htmlFilePath='/documents/privacy_statement.html'
          />
        }
      />
      <Route
        path='/user-agreement'
        element={
          <DocumentView title='User Agreement' htmlFilePath='/documents/user_agreement.html' />
        }
      />
      <Route
        path='/terms-and-conditions'
        element={
          <DocumentView
            title='Terms and Conditions'
            htmlFilePath='/documents/terms_and_conditions.html'
          />
        }
      />
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  </LayoutMain>
)

export default App
