import {Typography, Grid, Link} from '@mui/material'

import Title from '../Title'
import VideoSection from './VideoSection'
import {loginUSUrl} from 'config'

import {SliderContainer, ItemRight, ItemRightContainer} from './sliders.styles'

import data from 'en.json'

//TODO edit text in SliderSection/howToData when edit CryptoSlide
const CryptoSlide = () => (
  <SliderContainer>
    <Grid
      container
      columnSpacing={{
        xs: 0,
        sm: 3,
      }}
      rowGap={2}
    >
      <Title />
      <VideoSection src='assets/Slide2MetaMaskConnect.mp4' poster='/assets/Slide2Thumbnail.png'/>
      <ItemRight>
        <ItemRightContainer>
          <Typography variant='h1' color='text.reverse'>
            2
          </Typography>
          <Typography variant='h2' color='text.reverse'>
            {data.slider.slide2.title}
          </Typography>
          <Typography variant='body1' color='text.reverse'>
            {data.slider.slide2.text1}{' '}
            <Link color='text.reverse' target='_blank' underline='always' href={loginUSUrl}>
              {loginUSUrl}
            </Link>{' '}
            {data.slider.slide2.text2}
          </Typography>
        </ItemRightContainer>
      </ItemRight>
    </Grid>
  </SliderContainer>
)

export default CryptoSlide
