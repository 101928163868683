import {Box, Container, Typography, Button} from '@mui/material'
import appStope from 'images/apps/AppStore.svg'
import googlePlay from 'images/apps/GooglePlay.svg'

import {AppImage} from './AppsSection.styles'

const AppsSection = () => (
  <Container maxWidth='sm'>
    <Typography variant='h3' fontSize={{xs: 20}} align='center' padding={'20px 0 20px 0'}>
      DOWNLOAD OUR APP
    </Typography>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Button href='https://apps.apple.com/us/app/bretton-woods-gold/id6466785838' target='_blank'>
        <AppImage src={appStope} />
      </Button>
      <Button href='https://play.google.com/store/apps/details?id=com.platformapp' target='_blank'>
        <AppImage src={googlePlay} />
      </Button>
    </Box>
  </Container>
)

export default AppsSection
