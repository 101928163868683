export function remToPx(value) {
  return Math.round(parseFloat(value) * 16)
}

export function pxToRem(value) {
  return `${value / 16}rem`
}

export const reversePath = () => {
  const {search, pathname} = window.location
  const originPath = pathname.substring(0, pathname.lastIndexOf('/'))
  return originPath + search
}

export const currentPath = (hasPath = '') => {
  const {search, pathname} = window.location
  return pathname + hasPath + search
}

export const isAffiliateLegalNotice = window.location.pathname === '/affiliate/legal-notice'
