import {Box, Link, Typography, alpha, Grid} from '@mui/material'

import footerLogo from 'images/platform/footer-logo.svg'
import platformLogo from 'images/platform/platform-logo.svg'

import {footerLinks} from './Footer.config'
import {Image, Container, LinkWrapper} from './Footer.styles'
import TrackCustomPreference from 'consentCheck'

const isAffiliate = window.location.pathname === '/affiliate'

const Footer = () => (
  <Container>
    <Box sx={{display: {xs: 'none', md: 'flex'}}} alignItems='center'>
      <Image src={footerLogo} />
    </Box>

    <Box sx={{display: {xs: 'flex', md: 'none'}}} alignItems='center' flexGrow={1}>
      <Image src={footerLogo} />
      <Box ml={1} width={{xs: 250, lg: 300}} display='flex' flexDirection='column'>
        <Box component='img' src={platformLogo} width='100%' />
        <Typography
          fontSize={{xs: 16, lg: 19}}
          ml='3px'
          color={({palette: {text}}) => alpha(text.reverse, 0.75)}
        >
          Bretton Woods <i>digital</i> AG © {new Date().getFullYear()}
        </Typography>
      </Box>
    </Box>
    {/* <BoxSocialMedia sx={{display: {xs: 'none', sm: 'flex', md: 'none'}}} /> */}
    <LinkWrapper>
      {footerLinks.map(({id, text, link, _bankLink}) => (
        <Link
          key={id}
          target={
            (text === 'Whitepaper' || text === 'Security Certificate' || isAffiliate) && '_blank'
          }
          href={isAffiliate ? _bankLink : link}
          color='text.reverse'
          variant='footer'
          onClick={() =>
            TrackCustomPreference({
              action: 'Link Clicked',
              properties: {url: link, text: text, meaning: 'Open document'},
            })
          }
        >
          {text}
        </Link>
      ))}
    </LinkWrapper>
    {/* <BoxSocialMedia sx={{display: {xs: 'flex', sm: 'none', md: 'flex'}}} /> */}
    <Grid container sx={{display: {sm: 'none'}}}>
      {footerLinks.map(({id, text, link, _bankLink}) => (
        <Grid item key={id} xs={6}>
          <Link
            target={
              (text === 'Whitepaper' || text === 'Security Certificate' || isAffiliate) && '_blank'
            }
            href={isAffiliate ? _bankLink : link}
            color='text.reverse'
            variant='footer'
            onClick={() =>
              TrackCustomPreference({
                action: 'Link Clicked',
                properties: {url: link, text: text, meaning: 'Open document'},
              })
            }
          >
            {text}
          </Link>
        </Grid>
      ))}
    </Grid>
  </Container>
)

export default Footer
