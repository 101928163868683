import React from 'react'
import {Grid, Typography, List} from '@mui/material'

import {useMediaQuery} from 'hooks'

import FromRows from './FromRows'
import {Container, BottomContainer, BulletedListItem} from './Advantages.styles'
import {
  platfroms,
  representative,
  tradability,
  goldBacked,
  storageFees,
  ownership,
} from './advantages.config'

const tableTitle = 'Compared to other exchanges'

const Advantages = () => {
  const isMobile = useMediaQuery('sm', 'down')

  const Dataset = {
    '@context': ['https://schema.org', {csvw: 'https://www.w3.org/ns/csvw#'}],
    '@type': 'Dataset',
    description: 'comparison of Bretton Woods digital with other exchanges',
    name: tableTitle,
    mainEntity: {
      '@type': 'csvw:Table',
      'csvw:tableSchema': {
        'csvw:columns': [
          {
            'csvw:name': platfroms[1],
            'csvw:datatype': 'string',
            'csvw:cells': [
              {
                'csvw:value': representative[1],
                'csvw:primaryKey': representative[0],
              },
              {
                'csvw:value': tradability[1],
                'csvw:primaryKey': tradability[0],
              },
              {
                'csvw:value': goldBacked[1],
                'csvw:primaryKey': goldBacked[0],
              },
              {
                'csvw:value': storageFees[1],
                'csvw:primaryKey': storageFees[0],
              },
              {
                'csvw:value': ownership[1],
                'csvw:primaryKey': ownership[0],
              },
            ],
          },
          {
            'csvw:name': platfroms[2],
            'csvw:datatype': 'string',
            'csvw:cells': [
              {
                'csvw:value': representative[2],
                'csvw:primaryKey': representative[0],
              },
              {
                'csvw:value': tradability[2],
                'csvw:primaryKey': tradability[0],
              },
              {
                'csvw:value': goldBacked[2],
                'csvw:primaryKey': goldBacked[0],
              },
              {
                'csvw:value': storageFees[2],
                'csvw:primaryKey': storageFees[0],
              },
              {
                'csvw:value': ownership[2],
                'csvw:primaryKey': ownership[0],
              },
            ],
          },
          {
            'csvw:name': platfroms[3],
            'csvw:datatype': 'string',
            'csvw:cells': [
              {
                'csvw:value': representative[3],
                'csvw:primaryKey': representative[0],
              },
              {
                'csvw:value': tradability[3],
                'csvw:primaryKey': tradability[0],
              },
              {
                'csvw:value': goldBacked[3],
                'csvw:primaryKey': goldBacked[0],
              },
              {
                'csvw:value': storageFees[3],
                'csvw:primaryKey': storageFees[0],
              },
              {
                'csvw:value': ownership[3],
                'csvw:primaryKey': ownership[0],
              },
            ],
          },
          {
            'csvw:name': platfroms[4],
            'csvw:datatype': 'string',
            'csvw:cells': [
              {
                'csvw:value': representative[4],
                'csvw:primaryKey': representative[0],
              },
              {
                'csvw:value': tradability[4],
                'csvw:primaryKey': tradability[0],
              },
              {
                'csvw:value': goldBacked[4],
                'csvw:primaryKey': goldBacked[0],
              },
              {
                'csvw:value': storageFees[4],
                'csvw:primaryKey': storageFees[0],
              },
              {
                'csvw:value': ownership[4],
                'csvw:primaryKey': ownership[0],
              },
            ],
          },
        ],
      },
    },
  }

  return (
    <React.Fragment>
      <script type='application/ld+json'>{JSON.stringify(Dataset)}</script>
      <Container>
        <Typography variant='h2' color='text.reverse' sx={{padding: {xs: '10px', sm: '0'}}}>
          {tableTitle}
        </Typography>
        <Grid container mt={4}>
          <Grid container item xs={12}>
            <FromRows
              items={isMobile ? platfroms.slice(0, -1) : platfroms}
              borderBottom={false}
              bolder={['BWG']}
            />
          </Grid>
          <Grid container item xs={12}>
            <FromRows items={isMobile ? representative.slice(0, -1) : representative} />
          </Grid>
          <Grid container item xs={12}>
            <FromRows items={isMobile ? tradability.slice(0, -1) : tradability} />
          </Grid>
          <Grid container item xs={12}>
            <FromRows items={isMobile ? goldBacked.slice(0, -1) : goldBacked} />
          </Grid>
          <Grid container item xs={12}>
            <FromRows items={isMobile ? storageFees.slice(0, -1) : storageFees} />
          </Grid>
          <Grid container item xs={12}>
            <FromRows items={isMobile ? ownership.slice(0, -1) : ownership} borderBottom={false} />
          </Grid>
        </Grid>
        <BottomContainer>
          <Typography
            variant='body2'
            color='text.reverse'
            fontSize={{md: 20}}
            sx={{fontWeight: {xs: 'fontWeightMedium', sm: 'fontWeightRegular'}}}
          >
            <List>
              <BulletedListItem>
                • The best indication for whether an asset is backed by physical gold is to
                ascertain whether there are storage costs and how the company pays them.
              </BulletedListItem>
              <BulletedListItem>
                • An auditors report is essential for establishing that an asset is fully backed by
                gold.
              </BulletedListItem>
              <BulletedListItem>
                • Make sure that the gold security you are buying makes you the owner of the
                underlying gold.
              </BulletedListItem>
            </List>
          </Typography>
          <Typography variant='body2' color='text.reverse' fontSize={{xs: 9}} lineHeight='14px'>
            *As seen in the table above, the BWG asset differs from other assets by providing proof
            of validity. Bretton Woods <i>digital</i> displays the storage fees of the physical
            gold, unlike most other gold assets issuers. The costs are a clear sign that physical
            gold is deposited and available for audits.
          </Typography>
        </BottomContainer>
      </Container>
    </React.Fragment>
  )
}

export default Advantages
