import React from 'react'
import PropTypes from 'prop-types'
import {Grid, alpha, Box} from '@mui/material'

import {Title} from '../Advantages.styles'

const reverse = 'text.reverse'
const rowMap = {5: [3, 3, 2, 2, 2], 4: [3, 3, 3, 3]}

const rejectColorMap = {
  No: 'error.main',
  Unknown: 'error.main',
  Yes: reverse,
}

const colorMap = {
  ...rejectColorMap,
  Yes: 'success.main',
}

const FromRows = ({borderBottom, items, bolder = []}) => (
  <React.Fragment>
    {items.map((value, index) => (
      <Grid
        key={index}
        xs={rowMap[items.length][index]}
        item
        {...(index !== items.length - 1 && {
          borderRight: ({palette}) => `2px solid ${palette.text.reverse}`,
        })}
        {...(borderBottom && {
          borderBottom: ({palette}) =>
            `1px solid ${alpha(palette.text.reverse, palette.action.disabledOpacity)}`,
        })}
      >
        <Box
          padding={{
            xs: '3px',
            sm: 1,
          }}
          width='100%'
          height='100%'
          minHeight='60px'
          display='flex'
          alignItems='center'
          justifyContent={index === 0 ? 'flex-end' : 'center'}
        >
          {index > 1 ? (
            <Title fontWeight={400} color={rejectColorMap[value] ?? reverse}>
              {value}
            </Title>
          ) : (
            <Title
              textAlign={index === 0 ? 'end' : 'center'}
              color={colorMap[value] ?? reverse}
              {...(bolder.includes(value) && {fontWeight: 700, fontSize: {xs: 10, sm: 20, md: 24}})}
            >
              {value}
            </Title>
          )}
        </Box>
      </Grid>
    ))}
  </React.Fragment>
)

FromRows.propTypes = {
  borderBottom: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  bolder: PropTypes.array,
}

FromRows.defaultProps = {
  borderBottom: true,
}

export default FromRows
