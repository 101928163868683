import React from 'react'

const YoutubeLink = () => {
  const videoUrl = `https://www.youtube.com/embed/UEpxUU25xNw?rel=0`
  return (
    <div style={{width: '100%', marginTop: '20px'}}>
      <iframe
        width='100%'
        height='400'
        allowFullScreen
        style={{borderRadius: 8, border: 'none'}}
        src={videoUrl}
        title='YouTube video player'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
      ></iframe>
    </div>
  )
}

export default YoutubeLink
