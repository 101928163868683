import React from 'react'

import {useMediaQuery} from 'hooks'

const Tutorial = () => {
  const isMobile = useMediaQuery('sm', 'down')
  const videoUrl = `https://www.youtube.com/embed/LtN0x0tVMoc?rel=0`

  return (
    <div style={{width: '100%', marginTop: '70px'}}>
      <iframe
        width='100%'
        height={isMobile ? '400' : '650'}
        allowFullScreen
        style={{borderRadius: 8, border: 'none'}}
        src={videoUrl}
        title='YouTube video player'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
      ></iframe>
    </div>
  )
}

export default Tutorial
