import {Typography, Grid, Box} from '@mui/material'
import React from 'react'
import BWGCoinMockup from 'images/BWGCoinMockup.webp'
import {
  BWGContainer,
  ImageWrapper,
  ImageBox,
  ItemLeft,
  ItemRight,
  ItemLeftContainer,
  BigLeftContainer,
} from './BWGSection.styles.js'

export default function BWGSection() {
  return (
    <Box
      id='bwg-section'
      sx={{
        paddingTop: {xs: '60px', sm: '70px'},
      }}
    >
      <BWGContainer>
        <Grid
          container
          columns={20}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <ItemLeft>
            <BigLeftContainer>
              <ItemLeftContainer>
                <Typography variant='h3' color='text.reverse'>
                  Bretton Woods <i>digital</i> Gold
                </Typography>
                <Typography variant='body1' color='text.reverse'>
                  BWG provides you with a digital asset that enables you to conveniently invest into
                  gold.
                </Typography>
                <Typography variant='body1' color='text.reverse'>
                  With just your smartphone you can manage your gold-backed assets at any time.
                </Typography>
                <Typography variant='body1' color='text.reverse'>
                  With BWG, you can hedge against inflation without compromising liquidity.
                </Typography>
              </ItemLeftContainer>
            </BigLeftContainer>
          </ItemLeft>
          <ItemRight>
            <ImageWrapper>
              <ImageBox src={BWGCoinMockup}></ImageBox>
            </ImageWrapper>
          </ItemRight>
        </Grid>
      </BWGContainer>
    </Box>
  )
}
