import {Typography, Grid} from '@mui/material'
import React from 'react'
import ButtonBuyGold from 'components/ButtonBuyGoldModal/ButtonBuyGoldModal'
import HeroPic from 'images/HeroPic.webp'
import {
  HeroContainer,
  ImageWrapper,
  ImageBox,
  ItemLeft,
  ItemLeftContainerGradient,
  ItemLeftContainerSecond,
  ButtonContainer,
  ItemRight,
} from './HeroSection.styles'

export default function HeroSection() {
  return (
    <HeroContainer>
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: {xs: 'column', sm: 'row'},
          alignItems: 'center',
        }}
      >
        <ItemLeft>
          <ItemLeftContainerGradient>
            <Typography variant='h1' fontSize={{lg: 70}} lineHeight={{lg: '80px'}}>
              Gold secured wealth
            </Typography>
          </ItemLeftContainerGradient>
          <ItemLeftContainerSecond>
            <Typography variant='h3'>
              We make it simple for you to invest in gold in a quick and intelligent manner.
            </Typography>
          </ItemLeftContainerSecond>
          <ButtonContainer>
            <ButtonBuyGold />
          </ButtonContainer>
        </ItemLeft>
        <ItemRight>
          <ImageWrapper>
            <ImageBox src={HeroPic} />
          </ImageWrapper>
        </ItemRight>
      </Grid>
    </HeroContainer>
  )
}
