import {Typography} from '@mui/material'
import React from 'react'
import GreenBulB from 'images/sustainable/GreenBulB.png'
import {
  ItemLeftContainer,
  SustainabilityPicContainer,
  SustainabilityContainer,
  SustainabilityGradientContainer,
  ItemSide,
  ItemCenter,
  SustainabilityGrig,
  TopTextDiv,
  BulBImageBox,
  BulBImageBoxMobile,
  ListH3,
} from './Sustainability.styles'

export default function Sustainability() {
  return (
    <SustainabilityContainer>
      <SustainabilityPicContainer>
        <Typography variant='h1' color='text.reverse'>
          Sustainable <br />& Eco friendly
        </Typography>
      </SustainabilityPicContainer>
      <SustainabilityGradientContainer>
        <SustainabilityGrig>
          <ItemSide display={{xs: 'flex', sm: 'block'}} direction={{xs: 'row', sm: 'column'}}>
            <BulBImageBoxMobile src={GreenBulB} />
            <ItemLeftContainer>
              <ListH3>Efficient Coding</ListH3>
              <ListH3>Carbon Emission reduced</ListH3>
              <ListH3>Fuel saved</ListH3>
            </ItemLeftContainer>
          </ItemSide>
          <ItemCenter>
            <TopTextDiv>
              <Typography
                variant='body1'
                color='text.reverse'
                sx={{display: {xs: 'none', md: 'block'}}}
              >
                BWG represents an environmentally conscious digital asset. By operating on the
                Binance Smart Chain, it stands as a securely and proficiently generated digital
                asset. In the process it is highly energy-efficient, contributing only minimally to
                global energy usage. Thanks to the anti-mining process, it minimizes waste and is an
                eco-friendly asset.
              </Typography>

              <Typography variant='body1' color='text.reverse'>
                Since there is no transport or logistics of the physical gold happening after the
                trade, the amount of carbon emission is highly reduced. Instead, after your purchase
                we provide you with a digital certificate of ownership.
              </Typography>
            </TopTextDiv>
          </ItemCenter>
          <ItemSide>
            <BulBImageBox src={GreenBulB} />
          </ItemSide>
        </SustainabilityGrig>
      </SustainabilityGradientContainer>
    </SustainabilityContainer>
  )
}
