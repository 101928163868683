import {Grid, Typography} from '@mui/material'

import Title from '../Title'
import ButtonBuyGold from 'components/ButtonBuyGoldModal/ButtonBuyGoldModal'
import VideoSection from './VideoSection'

import {SliderContainer, ItemRight, ItemRightContainer} from './sliders.styles'

import data from 'en.json'

const BwgSlide = () => (
  <SliderContainer>
    <Grid
      container
      columnSpacing={{
        xs: 0,
        sm: 3,
      }}
      rowGap={2}
    >
      <Title />
      <VideoSection
        src='assets/Slide3PurchaseTransactionFinal.mp4'
        poster='/assets/Slide3Thumbnail.png'
      />
      <ItemRight>
        <ItemRightContainer>
          <Typography variant='h1' color='text.reverse'>
            3
          </Typography>
          <Typography variant='h2' color='text.reverse'>
            {data.slider.slide3.title}
          </Typography>
          <Typography variant='body1' color='text.reverse'>
            {data.slider.slide3.text}
          </Typography>
          <Typography>
            <ButtonBuyGold />
          </Typography>
        </ItemRightContainer>
      </ItemRight>
    </Grid>
  </SliderContainer>
)

export default BwgSlide
