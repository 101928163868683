import {currentPath} from 'utils'

export const footerLinks = [
  {
    id: 1,
    text: 'Legal Notice',
    link: '/legal-notice',
    _bankLink: currentPath('/legal-notice'),
  },
  {
    id: 2,
    text: 'User Agreement',
    link: '/user-agreement',
    _bankLink: '/documents/user_agreement.pdf',
  },
  {
    id: 3,
    text: 'Privacy Statement',
    link: '/privacy-statement',
    _bankLink: '/documents/privacy_statement.pdf',
  },
  {
    id: 4,
    text: 'Terms & Conditions',
    link: '/terms-and-conditions',
    _bankLink: '/documents/terms_and_conditions.pdf',
  },
  {
    id: 5,
    text: 'Whitepaper',
    link: '/documents/whitepaper.pdf',
    _bankLink: '/documents/whitepaper.pdf',
  },
  {
    id: 6,
    text: 'Security Certificate',
    link: '/documents/security_certificate.pdf',
    _bankLink: '/documents/whitepaper.pdf',
  },
  // {
  //   id: 7,
  //   text: 'FAQ',
  //   link: '/documents/terms_and_conditions.pdf',
  // },
]
