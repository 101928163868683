import {Container, AppBar, Toolbar, Box, Typography} from '@mui/material'

import {navLinks} from './Navbar.config'
import {isAffiliateLegalNotice} from 'utils'
import {Logo, LogoWrapper, Link} from './Navbar.styles'
import HamburgerMenu from 'components/HamburgerMenu/HamburgerMenu'

const Navbar = () => {
  return (
    <AppBar
      position='fixed'
      sx={{
        background: 'white',
        border: '1px solid #ccc',
        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
      }}
    >
      <Container>
        <Toolbar disableGutters>
          <LogoWrapper>
            <Logo />
            <Typography variant='body2' sx={{display: {xs: 'none', sm: 'block'}}}>
              Bretton Woods
              <br />
              <i>digital</i> Gold
            </Typography>
          </LogoWrapper>
          {!isAffiliateLegalNotice && (
            <Box display={{xs: 'none', md: 'flex'}} ml={8} columnGap={3}>
              {navLinks.map(({id, text, link, target}) => (
                <Link key={id} href={link} target={target}>
                  {text}
                </Link>
              ))}
            </Box>
          )}
          <Box
            flexGrow={1}
            display='flex'
            justifyContent='flex-end'
            alignItems='center'
            columnGap={{
              xs: 1,
              sm: 2,
            }}
          >
            {/*inactive while only 1 language is used on the platform*/}
            {/* <Box display={{xs: 'none', sm: 'block'}}>
                <Languages />
              </Box>*/}

            <Box display={{xs: 'block', md: 'none'}}>
              <HamburgerMenu />
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default Navbar
