import {Box, Stack, Container, Grid, Typography, Tooltip} from '@mui/material'
import GridUnstable from '@mui/material/Unstable_Grid2'

import ButtonBuyGold from 'components/ButtonBuyGoldModal/ButtonBuyGoldModal'
import metamask from 'images/metamask.webp'
import wallet from 'images/bwg-connect-wallet.webp'

import {wallets} from './wallets.config'
import {TokenImage, ContentWrapper, MetamaskGrid, WalletIcon} from './ConnectWallet.styles'
import {RegisterButton} from 'views/Affiliate/Affiliate.styles'

const ConnectWallet = ({affiliateLink, customerLink, isChecked}) => {
  const openRegisterLink = () => {
    window.open(affiliateLink, '_blank')
  }

  const openCustomerRegisterLink = () => {
    window.open(customerLink, '_blank')
  }

  return (
    <Container
      maxWidth='sm'
      disableGutters
      sx={{
        marginTop: 5,
        borderBottom: ({palette: {secondary}}) => `3px solid ${secondary.main}`,
        paddingBottom: {xs: 3, sm: 5},
      }}
    >
      <Grid container rowGap={3}>
        <Grid item xs={12}>
          <Grid
            container
            direction={{
              xs: 'column-reverse',
              sm: 'row',
            }}
            rowGap={1}
          >
            <Grid item xs={12} sm={7}>
              <Stack justifyContent='space-between' height='100%'>
                <TokenImage src={wallet} />
                <ContentWrapper>
                  <Typography variant='h2'>Connect your wallet</Typography>
                  <Typography variant='body1'>
                    Connect your wallet instantly to your MetaMask storage or one of the other
                    digital storage options listed below. With the certificates in your digital
                    storage, you will always maintain complete control over your investment.
                  </Typography>
                  {affiliateLink ? (
                    <>
                      {!isChecked ? (
                        <Tooltip
                          enterTouchDelay={0}
                          title={
                            <span style={{fontSize: 16}}>
                              You need to download and read the affiliate Terms & Conditions before
                              agreeing
                            </span>
                          }
                          arrow
                          placement='top-start'
                        >
                          <span>
                            <RegisterButton disableRipple disabled={!isChecked}>
                              Register as an affiliate
                            </RegisterButton>
                          </span>
                        </Tooltip>
                      ) : (
                        <RegisterButton onClick={openRegisterLink}>
                          Register as an affiliate
                        </RegisterButton>
                      )}
                      <RegisterButton onClick={openCustomerRegisterLink}>
                        Register as a customer
                      </RegisterButton>
                    </>
                  ) : (
                    <ButtonBuyGold />
                  )}
                </ContentWrapper>
              </Stack>
            </Grid>
            <MetamaskGrid item xs={12} sm={5}>
              <Box component='img' src={metamask} height={350} width='auto' />
            </MetamaskGrid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <GridUnstable container rowGap={2} columns={10}>
            <GridUnstable xs={2}>
              <WalletIcon {...wallets[0]} />
            </GridUnstable>
            <GridUnstable xs={2} />
            <GridUnstable xs={2}>
              <WalletIcon {...wallets[1]} />
            </GridUnstable>
            <GridUnstable xs={2} />
            <GridUnstable xs={2}>
              <WalletIcon {...wallets[2]} />
            </GridUnstable>
            <GridUnstable xs={2} />
            <GridUnstable xs={2}>
              <WalletIcon {...wallets[3]} />
            </GridUnstable>
            <GridUnstable xs={2} />
            <GridUnstable xs={2}>
              <WalletIcon {...wallets[4]} />
            </GridUnstable>
            <GridUnstable xs={2} />
          </GridUnstable>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ConnectWallet
