export const navLinks = [
  {
    id: 1,
    text: 'BWG',
    link: '#bwg-section',
    target: '_self',
  },
  {
    id: 2,
    text: 'Our Business',
    link: '#business-model',
    target: '_self',
  },
  {
    id: 3,
    text: 'Gold-Platform',
    link: '#gold-platform',
    target: '_self',
  },
  {
    id: 4,
    text: 'Whitepaper',
    link: '/documents/whitepaper.pdf',
    target: '_blank',
  },
  {
    id: 5,
    text: 'Security Certificate',
    link: '/documents/security_certificate.pdf',
    target: '_blank',
  },
]
