import {Typography, Grid} from '@mui/material'
import React from 'react'
import BusinessModel1 from 'images/business_model/BusinessModel1.png'
import BusinessModel2 from 'images/business_model/BusinessModel2.png'
import BusinessModel3 from 'images/business_model/BusinessModel3.png'
import {
  FirstContainer,
  Item,
  ImageWrapper,
  ImageBox,
  ItemFirst,
  ItemSecond,
  ItemThird,
  ImageBoxPhone,
  ImageWrapperPhone,
  BusinessModelContainer,
} from './BusinessModel.styles'

export default function BusinessModel() {
  return (
    <BusinessModelContainer id='business-model'>
      <FirstContainer>
        <Typography variant='h2' sx={{textAlign: {xs: 'left', md: 'center'}}}>
          All the gold in the world in the palm of your hands
        </Typography>
      </FirstContainer>
      <Grid container>
        <Item>
          <ImageWrapperPhone>
            <ImageBoxPhone src={BusinessModel1} />
          </ImageWrapperPhone>
          <ItemFirst>
            <ImageWrapper>
              <ImageBox src={BusinessModel1} />
            </ImageWrapper>
            <Typography variant='h3' textAlign={{xs: 'left', sm: 'center'}}>
              We purchase and securely store gold bullion in Swiss vaults
            </Typography>
            <Typography variant='body1' textAlign={{xs: 'left', sm: 'center'}}>
              We acquire gold bullion and physically secure it in a Swiss vault. The gold undergoes
              an audit in the vault to ensure its purity and quantity.
            </Typography>
          </ItemFirst>
        </Item>
        <Item>
          <ImageWrapperPhone>
            <ImageBoxPhone src={BusinessModel2} />
          </ImageWrapperPhone>

          <ItemSecond>
            <ImageWrapper>
              <ImageBox src={BusinessModel2} />
            </ImageWrapper>
            <Typography variant='h3' textAlign={{xs: 'left', sm: 'center'}}>
              We issue gold certificates for the gold in storage
            </Typography>
            <Typography variant='body1' textAlign={{xs: 'left', sm: 'center'}}>
              For each gram of gold we hold in storage, we create 1 BWG, ensuring that every gold certificate is fully backed by physical gold.
            </Typography>
          </ItemSecond>
        </Item>
        <Item>
          <ImageWrapperPhone>
            <ImageBoxPhone src={BusinessModel3} />
          </ImageWrapperPhone>
          <ItemThird>
            <ImageWrapper>
              <ImageBox src={BusinessModel3} />
            </ImageWrapper>
            <Typography variant='h3' textAlign={{xs: 'left', sm: 'center'}}>
              We offer the BWG digital asset for sale on our exchange
            </Typography>
            <Typography variant='body1' textAlign={{xs: 'left', sm: 'center'}}>
              We transfer the digital assets directly to the user to ensure that the funds are
              immediately under their complete control.
            </Typography>
          </ItemThird>
        </Item>
      </Grid>
    </BusinessModelContainer>
  )
}
