import React, {useState} from 'react'
import {Button, Modal, Box, Typography, Radio, RadioGroup, FormControlLabel} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import {useMediaQuery} from 'hooks'
import TrackCustomPreference from 'consentCheck'

import USAFlag from 'images/flags/usa.svg'
import EUFlag from 'images/flags/eu.svg'
import {ModalBox, LoginButton, RegisterButton, GreyBox} from './ButtonBuyGoldModal.styles'
import {loginUSUrl, loginEUUrl, registerEUUrl, registerUSUrl} from 'config'

export function ButtonBuyGoldModal() {
  const isMobile = useMediaQuery('sm', 'down')

  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const buttonText = 'Buy gold now'
  const usa = 'usa'
  const europe = 'europe'

  const [selectedValue, setSelectedValue] = useState(usa)
  const [loginurl, setLoginUrl] = useState(loginUSUrl)
  const [registerurl, setRegisterUrl] = useState(registerUSUrl)

  const handleChange = event => {
    setSelectedValue(event.target.value)

    if (event.target.value === usa) {
      setRegisterUrl(registerUSUrl)
      setLoginUrl(loginUSUrl)
    } else if (event.target.value === europe) {
      setRegisterUrl(registerEUUrl)
      setLoginUrl(loginEUUrl)
    }
  }
  return (
    <>
      <Button
        onClick={() => {
          handleOpen()
          TrackCustomPreference({
            action: 'Button Clicked',
            properties: {
              text: buttonText,
              meaning: 'Open register/login popup',
            },
          })
        }}
        target={isMobile ? '_self' : '_blank'}
        sx={{
          borderRadius: '4px',
          boxShadow: '0px 0px 6px 0px #00000040',
          background: 'linear-gradient(180deg, rgba(215, 182, 111, 0.81) 0%, #D7B66F 100%)',
          color: 'black',
          height: {xs: '38px', sm: '38px'},
          width: {xs: '138px', sm: '145px'},
        }}
      >
        {buttonText}
      </Button>
      <Modal open={open} onClose={handleClose}>
        <ModalBox>
          <CloseIcon
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: '35px',
              right: '15px',
              cursor: 'pointer',
            }}
          />
          <Typography padding={'30px'} variant='h3'>
            Select a platform
          </Typography>
          <Box paddingBottom={'30px'}>
            <RadioGroup row defaultValue={usa} value={selectedValue} onChange={handleChange}>
              <FormControlLabel
                value={usa}
                control={<Radio />}
                label={
                  <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Typography
                      variant='body1'
                      sx={{
                        fontSize: '15px',
                      }}
                    >
                      USA
                    </Typography>
                    <Box
                      paddingTop='7px'
                      paddingRight='15px'
                      component='img'
                      src={USAFlag}
                      width='100%'
                    />
                  </Box>
                }
              />
              <FormControlLabel
                value={europe}
                control={<Radio />}
                label={
                  <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Typography
                      variant='body1'
                      sx={{
                        fontSize: '15px',
                      }}
                    >
                      EUROPE
                    </Typography>
                    <Box paddingTop='7px' component='img' src={EUFlag} width='100%' />
                  </Box>
                }
              />
            </RadioGroup>
          </Box>
          <GreyBox>
            <Typography
              variant='body2'
              sx={{
                fontSize: '16px',
                lineHeight: '28px',
              }}
            >
              If you are based in the United States of America you need to use our USA Platform
              which we have specifically developed for that market. For all other customers please
              check the list of excluded countries during the registration on the Platform itself.
              As long as your country is not excluded on the Platform you can register there. So you
              are welcome to create accounts on either or both Platforms if possible
            </Typography>
          </GreyBox>
          <Box paddingBottom={'20px'} sx={{display: 'flex', alignItems: 'center'}}>
            <LoginButton href={loginurl} />
            <RegisterButton href={registerurl} />
          </Box>
        </ModalBox>
      </Modal>
    </>
  )
}

export default ButtonBuyGoldModal
